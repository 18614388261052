export const REPs = [
    {
        providerId: '269',
        providerName: 'GoodCharlie',
        host: true
    },
    {
        providerId: '163',
        providerName: 'Direct Energy'
    },
    {
        providerId: '176',
        providerName: 'TXU Energy'
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '183',
        providerName: 'Champion Energy'
    }
];

export const API_KEY = '5edfb8859ed4cb5fb935adfa528daabd';
export const API_URL = 'https://0gk5f0wlpd.execute-api.us-east-2.amazonaws.com/v1/SideBySide';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';