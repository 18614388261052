import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button, Container, TextField, Grid, MenuItem,
    InputAdornment, ButtonGroup
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { SectionWrapper, CopmareSection } from "./styles/saving-section.styled";
import { EntrySection, FeatureBlock, FilterLabel, SavingRow } from "./styles/common.styled";
import { CompareChart } from "./CompareChart";
import { fetchTerms, fetchUtility, fetchPlan } from "../fetch/fetch";
import { REPs } from "../config";
import { PlanTypes, ChartDataTypes, UtilityTypes } from "../types";
import NumberFormat from 'react-number-format';
import { PlanModal } from "./PlanModal";
import { RollingNumber } from "./RollingNumber";
import { Box } from "@mui/system";
import { withSnackbar } from './SnackbarHOC';
import { Loading } from "./Loading";
import { MyTooltip } from "./MyTooltip";
import { ReviewSection } from "./ReviewSection";
import { BenefitSection } from "./BenefitSection";

const SavingSection = ({
    snackbarShowMessage,
    utms
}: {
    snackbarShowMessage?: Function;
    utms: string;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ zipcode, setZipcode ] = useState('');
    const [ selectedTerm, setSelectedTerm ] = useState('');
    const [ utilities, setUtilities ] = useState<UtilityTypes[]>([]);
    const [ selectedUtility, setSelectedUtility ] = useState('');
    const [ termOptions, setTermOptions ]= useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ avgMonthlyUse, setAvgMonthlyUse ] = useState('1000');
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ oneRepPlanList, setOneRepPlanList ] = useState<PlanTypes[]>([]);
    const [ plans, setPlans ] = useState<{[key: string]: PlanTypes[]}|undefined>(undefined);
    const mainRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (zipcode.length === 5 && !loading) {
            setLoading(true);
            setSelectedUtility('');
            setUtilities([]);
            setSelectedTerm('');
            setChartData([]);
            getUtility();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    const getUtility = () => {
        fetchUtility(zipcode).then(res => {
            if (res && res[0] && res[0].utility_code) {
                setUtilities(res);

                if (res[0] && res[0].utility_code) {
                    setSelectedUtility(res[0].utility_name);
                }
            }
            else {
                snackbarShowMessage?.(res.message, 'error');
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        const utilityCode = findUtilityCode();
        
        if (utilityCode) {
            setSelectedTerm('');
            
            fetchTerms(utilityCode).then(res => {
                if (res && res.code === 1) {
                    let terms = res.response;
                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1;
                        if (parseInt(a) > parseInt(b)) return 1;
                        return 0;
                    });
                    setTermOptions(terms);
                    if (terms.length > 0) {
                        if (terms.indexOf('12') >= 0) {
                            setSelectedTerm('12');
                        }
                        else {
                            setSelectedTerm(terms[0]);
                        }
                    }
                }
                else {
                    snackbarShowMessage?.(res.msg, 'error');
                    setLoading(false);
                }
            })
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUtility])

    useEffect(() => {
        if (selectedTerm && selectedUtility) {
            if (utilities.length > 1 && chartData.length === 0) {
                setLoading(false);
            }
            else {
                getPlan();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTerm, avgMonthlyUse])

    const handleScroll = () => {
        if (mainRef && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const findUtilityCode = () => {
        if (!selectedUtility) return '';

        return utilities.filter((val) => val.utility_name === selectedUtility)[0].utility_code;
    }

    const getPlan = () => {
        const usages = new Array(12).fill(parseInt(avgMonthlyUse));

        const utilityCode = findUtilityCode();

        setLoading(true);
        fetchPlan(
            parseInt(selectedTerm),
            utilityCode,
            usages
        ).then(res => {
            setLoading(false);
            let data = [];

            if (res && res.code === 1) {
                const allPlans = res.response;
                for (let i in allPlans) {
                    const plans = allPlans[i];
                    let totalRate = 0;
                    plans.forEach((val: PlanTypes, idx: number) => {
                        const { total_rate } = val;
                        totalRate += total_rate;
                    });
                    
                    data.push({
                        id: i,
                        value: Math.ceil(totalRate / plans.length * 100) / 100,
                        label: REPs.filter(val => val.providerId === i)[0].providerName
                    });
                }

                setPlans(allPlans);
                handleSetChartData(data);
                setTimeout(() => {
                    handleScroll();
                }, 200);
            }
            else {
                snackbarShowMessage?.(res.msg, 'error');
            }
        })
    }

    const handleSetChartData = (data: ChartDataTypes[]) => {
        let arr = data;

        arr.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });

        let _chartData = [];
        const hostRep = REPs.filter(val => val.host === true);
        const hostData = arr.filter(val => val.id === hostRep[0].providerId);
        
        if (hostData[0]) {
            _chartData.push(hostData[0]);
        }

        const hostDataIdx = arr.findIndex(val => val.id === hostData[0].id);
        arr.splice(hostDataIdx, 1);
        _chartData = _chartData.concat(arr.slice(-3));
        
        setChartData(_chartData);
    }

    const handleChangeZipcode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNums = evt.target.value.replace(/[^0-9]/g, '');
        setZipcode(onlyNums);
    }

    let energySavings = 0;
    const packBenefits = 1750;
    if (chartData && chartData.length > 0) {
        const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
        energySavings = rateDiff * parseFloat(avgMonthlyUse) * parseInt(selectedTerm) / 100;
    }

    return (
        <SectionWrapper>
            <EntrySection>
                <Container>
                    <Grid container position="relative" alignItems="center">
                        <Grid item xs={12} sm={6} position="relative" zIndex="1" textAlign="left" sx={{pt:8,pb:8}}>
                            <h2 style={{marginTop:0}}><b>Our Energy Rates Are a <span>Real Treat!</span></b></h2>
                            <p>Enter your zip code and see how much you can save when compared against the big dogs.</p>
                            <Grid container spacing={3} sx={{mt:4,textAlign:'center'}}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        label="Enter your zip code"
                                        value={zipcode}
                                        onChange={handleChangeZipcode}
                                        inputProps={{ maxLength: 5 }}
                                        InputProps={{
                                            endAdornment: loading ? <InputAdornment position="end"><Loading /></InputAdornment> : null
                                        }}
                                        fullWidth
                                        disabled={loading}
                                    />
                                </Grid>
                                {
                                    (utilities && utilities.length > 1) &&
                                    <Grid item xs={12} md={5}>
                                        <Select
                                            labelId="utility-label"
                                            id="utility"
                                            value={selectedUtility}
                                            onChange={(evt: SelectChangeEvent) => {
                                                setSelectedUtility(evt.target.value as string);
                                            }}
                                            disabled={loading}
                                            fullWidth
                                            sx={{textAlign:'left',borderRadius:4}}
                                        >
                                            {
                                                utilities.map((val: any, idx) => <MenuItem value={val.utility_name} key={idx}>{val.utility_name}</MenuItem>)
                                            }
                                        </Select>
                                    </Grid>
                                }
                            </Grid>
                            {
                                (utilities && utilities.length > 1) &&
                                <Box sx={{mt:4,textAlign:{xs:'center',sm:'left'}}}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        onClick={getPlan}
                                        disabled={loading || !zipcode || !selectedUtility}
                                    >Continue</Button>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign="right">
                            <img src="/images/laptop.png" width="100%" alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </EntrySection>
            <Container id="compare-section" ref={mainRef}>
                {
                    (selectedTerm && chartData.length > 0) &&
                    <CopmareSection>
                        <h2>GoodCharlie utilizes the latest technology to make real time market comparisons</h2>
                        <h3>
                            Estimated Total Savings: <RollingNumber data={energySavings + packBenefits} styles={{color:'#0BCEBB',fontWeight:700}} />
                            <MyTooltip title="Total savings equates to the value of Pack Benefits plus Energy Savings" />
                        </h3>
                        <Grid container alignItems="start" sx={{flexDirection:{xs:'column',sm:'column',md:'row'},mt:{xs:2,sm:2,md:0}}}>
                            <SavingRow>Pack Benefits: <NumberFormat value={packBenefits} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                            <MyTooltip title="Estimated value of $1,000 in annual vet bills for up to 5 pets ($200x5), plus $750 for a single pet emergency." /></SavingRow>
                            <SavingRow>Energy Savings: <NumberFormat value={energySavings} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                            <MyTooltip title="Annual savings calculated by comparing our rate with the average rate from the most expensive REP multiplied by the selected average monthly usage amount multiplied by the term." /></SavingRow>
                        </Grid>
                        <Grid container alignItems="center" sx={{mt:2,mb:4,justifyContent:{xs:'center',sm:'flex-end'},flexDirection:{xs:'column',sm:'row'}}}>
                            <Grid item display="flex" flexDirection="column" sx={{mr:{xs:0,sm:3},textAlign:{xs:'center',sm:'left'}}}>
                                <FilterLabel>Avg. Monthly Use:</FilterLabel>
                                <ButtonGroup sx={{mt:2}}>
                                    {
                                        ['500', '1000', '2000'].map((val, idx) => 
                                        <Button sx={{borderRadius:4}} variant={avgMonthlyUse === val ? 'contained' : 'outlined'} key={idx} onClick={() => {
                                            setAvgMonthlyUse(val);
                                        }}>{`${val} kWh`}</Button>)
                                    }
                                </ButtonGroup>
                            </Grid>
                            <Grid item display="flex" flexDirection="column" sx={{textAlign:{xs:'center',sm:'left'},mt:{xs:3,sm:0}}}>
                                <FilterLabel>Term:</FilterLabel>
                                <Select
                                    labelId="term-select-label"
                                    id="term-select"
                                    value={selectedTerm}
                                    onChange={(evt: SelectChangeEvent) => {
                                        setSelectedTerm(evt.target.value as string);
                                    }}
                                    disabled={loading}
                                    sx={{borderRadius:4,mt:2}}
                                >
                                    {
                                        termOptions.map((val, idx) => <MenuItem value={val} key={idx}>{`${val} Months`}</MenuItem>)
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                        <CompareChart
                            chartData={chartData}
                            handleShowPlan={(id: string) => {
                                setShowModal(true);
                                if (plans && plans[id]) {
                                    setOneRepPlanList(plans[id]);
                                }
                            }}
                        />
                        <Grid container flexDirection="column" alignItems="center" justifyContent="center" mt={8}>
                            <Grid sx={{fontSize:'1.25rem',color:'#666'}}>
                                For a personalized savings calculator based on your actual consumption, your choice of term and in your area <a href="javascript:;" style={{color:'#0BCEBB',textDecoration:'none'}} onClick={() => {
                                    let quary = '';
                                    if (location && location.search) {
                                        const { search } = location;
                                        if (search.split('user=')[1] === 'eiq') {
                                            quary = '?user=eiq'
                                        }
                                    }
                                    navigate(`/compare${quary}`);
                                }}>click here</a>
                            </Grid>
                            <Grid mt={5}>
                                <Button size="large" variant="contained" onClick={() => {
                                    window.location.href = `https://enrollment.goodcharlie.com/${utms ? `?${utms}` : ''}`
                                }}>Join The Pack</Button>
                            </Grid>
                        </Grid>
                    </CopmareSection>
                }
            </Container>
            <Container sx={{mt:10}}>
                <h2 style={{fontSize:'1.875rem',fontWeight:300,maxWidth:855,margin:'0 auto',lineHeight:1.5,marginBottom:32}}>Save even more by using GoodCharlie’s custom alerts and notification technology allowing you to save cash and energy while maintaining maximum control</h2>
                <FeatureBlock>
                    <Grid container>
                        <Grid item xs={12} sm={7}>
                            <h3><span>Automated alerts</span> that keep you informed and in control</h3>
                            <p>Simply set up alter that always keep you on top of your energy usage, budget, and new invoice and contract end dates.</p>
                            <ul>
                                <li>Alerts delivered automatically via text/SMS or email</li>
                                <li>Never get surprised again. GoodCharlie keeps you aware!</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={5}><img src="/images/feature-img-1.png" alt="" width="350" /></Grid>
                    </Grid>
                </FeatureBlock>
                <FeatureBlock>
                    <Grid container sx={{flexDirection:{xs:'column-reverse',sm:'row'}}}>
                        <Grid item xs={12} sm={5}><img src="/images/feature-img-2.png" alt="" width="350" /></Grid>
                        <Grid item xs={12} sm={7}>
                            <h3>Easy-to-understand energy <span>data and insights</span></h3>
                            <p>Gain deep insights into your home energy through data visualization that you don’t have to be an energy expert to understand</p><p>Mulitple view options available to address various preferences and scenarios providing ultimate transparency for even the most complex data</p>
                            <p>Become your home’s “No Effort” energy expert!</p>
                        </Grid>
                    </Grid>
                </FeatureBlock>
            </Container>
            <Box mt={4} mb={10}>
                <BenefitSection utms={utms} />
            </Box>
            <Box sx={{backgroundColor:'#fff6f8',paddingTop:10,paddingBottom:10}}>
                <ReviewSection />
                <Container sx={{mt:12}}>
                    <Box sx={{
                        backgroundColor: '#FFE9EE',
                        borderRadius: 20,
                        padding: '0 56px',
                        position: 'relative',
                        overflow: {xs:'hidden',sm:'inherit'}
                    }}>
                        <Grid container alignItems="flex-end" sx={{flexDirection:{xs:'column',sm:'row'},paddingBottom:{xs:40,sm:0}}}>
                            <Grid item xs={12} sm={6} sx={{padding:'44px 0',textAlign:{xs:'center',sm:'left'}}}>
                                <h2 style={{marginTop:0,marginBottom:12,fontSize:34,justifyContent:'flex-start'}}>Ready to Join The Pack?</h2>
                                <p style={{fontSize:16,textAlign:'left'}}>Use your energy to make Texas the best place for a dog to live today.</p>
                                <Button size="large" variant="contained" onClick={() => {
                                    window.location.href = `https://enrollment.goodcharlie.com/${utms ? `?${utms}` : ''}`
                                }} sx={{mt:3}}>Join The Pack</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} textAlign="right" fontSize="0" sx={{position:{xs:'static',sm:'relative'}}}>
                                <img src="/images/happy_woman_hugging_dog.webp" width="360" alt="" style={{position:'absolute',bottom:0,right:0}} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <PlanModal
                show={showModal}
                data={oneRepPlanList}
                handleCloseModal={() => { setShowModal(false); }}
            />
        </SectionWrapper>
    )
}

export default withSnackbar(SavingSection)