import { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";

const SPEED = 300;

export const RollingNumber = ({ data, type, styles }: {
    data: number;
    type?: string;
    styles?: Object;
}) => {
    const [ value, setValue ] = useState(0);
    const t = useRef<null | ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (data) {
            updateCount(data);
        }

        return () => {
            if (t.current) clearInterval(t.current);
        }
    }, [data])

    const updateCount = (target: number) => {
        const increment = target / SPEED;
        let _total = 0;
        t.current = setInterval(() => {
            if (_total < target) {
                _total += increment;
                setValue(_total);
            }
            else {
                setValue(target);
                if (t && t.current) {
                    clearInterval(t.current);
                }
            }
        }, 1);
    }

    return (
        <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={type === 'number' ? '' : '$'}
            decimalScale={type === 'number' ? 0 : 2}
            fixedDecimalScale={true}
            style={styles}
        />
    )
}
