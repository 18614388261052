import SavingSection from "./components/SavingSection";

const Homepage = ({ utms }: {
    utms: string;
}) => {
    return (
        <>
            <SavingSection utms={utms} />
        </>
    )
}

export default Homepage