// import { styled } from '@mui/system';
import styled from "@emotion/styled/macro";

export const StyledHeader = styled('header')`
    padding: 0px 6vw 0px 3.8vw;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    transition: background-color .3s;
`

export const Logo = styled('a')`
    text-decoration: none;
    color: #666;
    font-size: 12px;
    display: flex;
    align-items: center;
    
    img {
        height: 36px;
    }

    @media (max-width: 991px) {
        img {
            height: 24px;
        }
    }
`

export const PageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;

    h2 {
        font-size: 3rem;
        color: #3a3a3a;
        font-weight: 700;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h2 span {
        color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
    }

    @media (max-width: 991px) {
        h2 {
            font-size: 2.125rem;
            line-height: 1.2;
            text-align: left;
        }
    }
`

export const PageMain = styled('div')`
    flex: 1;
`

export const ChartWrapper = styled('div')`
    height: 400px;
    width: 100%;
    margin: 24px 0;

    @media (max-width: 991px) {
        height: 320px;
    }

    @media (max-width: 768px) {
        height: 260px;
    }
`

export const StyledFooter = styled('div')`
    background-color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
    color: #fff;
    padding: 4.5rem 6.8vw 3rem 7.43vw;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-feature-settings: "ordn";
    margin-top: 44px;

    a {
        color: #fff;
        text-decoration: none;
    }

    @media (max-width: 768px) {
        align-items: flex-start;
        padding-bottom: 80px;
    }
`

export const FooterSocialLinks = styled('div')`
    display: flex;
    align-items: center;

    a {
        display: inline-flex;
        height: 38px;
        width: 38px;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: transparent;
        transition: all 0.2s ease-out 0s;
        margin-left: 16px;
    }

    a:first-of-type {
        margin-left: 0;
    }

    a:hover, a:focus {
        background-color: #fff;
    }

    a:hover path, a:focus path {
        fill: ${({ theme }: { theme: any }) => theme.palette.primary.main};
    }
`

export const ChartTooltips = styled('div')`
    background: #fff;
    border: 1px solid #CEF5F1;
    border-radius: 8px;
    padding: 12px 16px;
    text-align: left;

    h6 {
        font-size: 12px;
        font-weight: 700;
        color: #3a3a3a;
        margin: 0 0 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #f2f2f2;
    }

    p {
        margin: 0;
    }
`

export const ViewMoreBtnRow = styled('div')`
    display: flex;
    padding-left: 2%;

    button {
        box-shadow: none;
    }

    & > div {
        flex: 1;
        text-align: center;
    }

    @media (max-width: 768px) {
        padding-left: 4%;

        button {
            font-size: 11px;
            padding: 2px 6px;
        }
    }
`

export const HeaderMenu = styled('div')`
    display: flex;
    align-items: center;
    
    button {
        span {
            transition: all 0.25s ease 0s;
        }
        img {
            opacity: 0;
            margin-right: -10px;
            transition: opacity 0.25s ease 0s;
        }
    }

    button:hover {
        span {
            transform: translateX(-12px);
        }
        img {
            opacity: 1;
        }
    }

    @media (max-width: 991px) {
        display: ${({ active }: { active: string }) => (active && active === 'true') ? 'flex' : 'none'};
        position: fixed;
        top: 52px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.375rem 0px 0.625rem 3.5rem;
        
        button {
            margin-bottom: 1.375rem;
            font-size: 1.25rem;
            padding: 0.75rem 2.125rem;
        }
    }
`

export const HeaderMenuDropdown = styled('div')`
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    height: auto;
    background-color: #fff;
    padding: 0.625rem 0.375rem;
    z-index: 6;
    border: 1px solid #4a4a4a1a;
    box-shadow: rgb(224 224 224 / 20%) 0px 9px 30px;
    border-radius: 14px;

    a {
        display: block;
        text-decoration: none;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 24px;
        color: #3a3a3a;
        padding: 0.75rem;
        transition: all 0.25s ease 0s;
        border-radius: 8px;
        min-width: 260px;
    }

    a:hover {
        color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
        background-color: #6f6c6c0d;
    }

    @media (max-width: 991px) {
        position: static;
        box-shadow: none;
        border: 0 none;
        left: auto;
        transform: none;
        padding-top: 0;
        padding-bottom: 0;
        transition: max-height .3s;
        overflow: hidden;

        a {
            font-size: 1.25rem;
            line-height: 1.5;
            color: #21212185;
            padding: 0.375rem;
            border-radius: 8px;
        }
    }
`

interface HeaderMenuItemProps {
    hasDropdown?: boolean,
    active?: boolean
}

export const HeaderMenuItem = styled('div')<HeaderMenuItemProps>`
    position: relative;
    margin-right: 48px;

    & > a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 24px;
        color: #3a3a3a;
        padding: 1.625rem 0px;
        position: relative;
        transition: all 0.25s ease 0s;
    }
    
    i {
        display: inline-block;
        width: 24px;
        height: 24px;
        mask: url('/images/ChevronDown.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: #3a3a3a;
        margin-left: 4px;
        transform: ${({ active }) => active ? 'rotate(180deg)' : 'rotate(0)'};
    }

    svg {
        stroke: #3a3a3a;
        transition: all 0.25s ease 0s;
    }

    :hover {
        & > a {
            color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
        }

        i {
            background-color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
        }

        svg {
            stroke: #ff5a6a;
        }

        ${HeaderMenuDropdown} {
            display: block;
        }
    }

    @media (max-width: 991px) {
        margin-right: 0;
        width: 90%;
        font-size: 1.25rem;
        margin-bottom: 24px;

        & > a {
            width: 100%;
            justify-content: space-between;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 26px;
            letter-spacing: -0.1px;
            color: #3a3a3a;
            padding: 0.5rem 0px;
            margin-bottom: 8px;
        }

        &:last-of-type {
            margin-bottom: 2.75rem;
        }

        ${HeaderMenuDropdown} {
            max-height: ${({ active }) => active ? '999px' : 0};
        }
    }
`

export const StyledHeaderInner = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        box-shadow: none;
        margin-right: 1rem;

        :last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 991px) {
        height: 52px;
    }
`

export const EntrySection = styled('div')`
    background-color: #FFF7F8;
    margin-bottom: 64px;
    padding: 44px 0;

    @media (max-width: 991px) {
        padding-left: 4px;
        padding-right: 4px;
        
        h2 {
            font-size: 3rem;
        }
    }
`

export const MenuToggle = styled('button')`
    cursor: pointer;
    border: 0 none;
    mask-image: ${({ active }: { active: string }) => (active && active === 'true') ? 'url("/images/X.svg")' : 'url("/images/Menu.svg")'};
    mask-position: 0 0;
    mask-repeat: no-repeat;
    background-color: #888;
    width: 24px;
    height: 24px;
    mask-size: cover;
    display: none;
    margin-right: 0 !important;

    @media (max-width: 991px) {
        display: inline-block;
    }
`

export const ModalBodyStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 650,
        xs: '85%'
    },
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
};

export const ChartFooter = styled('div')`
    margin-top: 24px;
    color: #999;
    font-size: 13px;
    text-align: center;
`

export const ModalCloseBtn = styled('button')`
    display: inline-block;
    width: 32px;
    height: 32px;
    mask: url('/images/X.svg') 0 0 no-repeat;
    background-color: ${({ theme }: { theme: any }) => theme.palette.primary.main};
    border: 0 none;
    cursor: pointer;
`

export const SavingRow = styled('div')`
    display: flex;
    align-items: center;
    margin: 4px 0;
    font-size: 20px;
    font-weight: 700;
    border-right: 1px solid ${({ theme }: { theme: any }) => theme.palette.secondary.light};
    margin-right: 24px;
    padding-right: 24px;

    &:last-child {
        border-right: 0 none;
        margin-right: 0;
        padding-right: 0;
    }

    span {
        color: ${({ theme }: { theme: any }) => theme.palette.secondary.main};
        margin-left: 12px;
    }

    @media (max-width: 768px) {
        border-right: 0 none;
        margin-right: 0;
        padding-right: 0;

        span {
            margin-left: 0;
        }
    }
`

interface SpinnerProps {
    sm?: boolean
}

export const Spinner = styled('div')<SpinnerProps>`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }

    display: inline-block;
    width: ${({ sm }) => sm ? '18px' : '24px'};
    height: ${({ sm }) => sm ? '18px' : '24px'};
    background: url('/images/loading.png') 0 0 no-repeat;
    background-size: cover;
    animation: spin 1.2s linear infinite;
`

export const FilterLabel = styled('div')`
    font-size: 14px;
    color: #666;
    letter-spacing: -0.1px;
`

export const TooltipIcon = styled('i')`
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/images/info.png') 0 0 no-repeat;
    background-size: cover;
    margin-left: 8px;
    flex-shrink: 0;
    cursor: help;
`

export const FeatureBlock = styled('div')`
    text-align: left;
    padding: 44px 0;

    h3 {
        font-size: 34px;
    }

    h3 span {
        color: ${({ theme }: { theme: any }) => theme.palette.secondary.main};
    }

    p, li {
        font-size: 14px;
    }

    p {
        margin: 12px 0;
        line-height: 24px;
        text-align: left;
    }
`