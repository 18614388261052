import React, { useEffect, useRef, useState } from 'react';
import he from 'he';
import { useLocation } from 'react-router-dom';
import type { ShowAgreementProps, ConfirmModalProps, SmtMonthlyReads } from '../../types';
import { LoadingRow } from '../LoadingRow';
import { Button, Modal, Box } from '@mui/material';
import moment from "moment";
import { fetchMonthlyUsage, getAgreement } from '../../fetch/fetch';
import qs from 'qs';
import { ModalBodyStyle } from '../styles/common.styled';
import { withSnackbar } from '../SnackbarHOC';
import { Loading } from '../Loading';

const SmtShowAgreement: React.FC<ShowAgreementProps> = ({
    consentId,
    handleBack,
    handleSetUsage,
    email,
    snackbarShowMessage
}) => {
    const location = useLocation();
    const { esiid } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const agreementContent = useRef<HTMLDivElement>(null);
    const [ agreement, setAgreement ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ confirmUrl, setConfirmUrl ] = useState('');
    const [ showConfirmModal, setShowConfirmModal ] = useState(false);

    useEffect(() => {
        if (consentId && !agreement) {
            handleCheckAgreement('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentId, agreement])

    const handleCheckAgreement = (type: string) => {
        setLoading(true);
        
        getAgreement(consentId).then(res => {
            if (res) {
                if (res.error_code && res.error_code === '500') {
                    setLoading(false);
                    snackbarShowMessage?.(res.message, 'error');
                    // console.error(res.message);
                    handleBack();
                    return;
                }
                const status = res.status.toLowerCase()
                if (status === 'new') {
                    setTimeout(() => {
                        handleCheckAgreement('get')
                    }, 2000);
                }
                if (status === 'waiting for approval') {
                    if (type === 'get') {
                        setAgreement(res.response.form_content)
                        setConfirmUrl(res.response.agree_btn_url)
                        setLoading(false);
                    }
                    else {
                        // type: confirm
                        setTimeout(() => {
                            handleCheckAgreement('confirm')
                        }, 2000);
                    }
                }
                if (status === 'approved') {
                    getMonthlyUsage();
                }
            }
            else {
                setLoading(false);
                console.log('error: get agreement')
            }
        })
    }

    const getMonthlyUsage = () => {
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY');
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
        fetchMonthlyUsage(`${esiid}`, consentId, startDate, endDate).then(res => {
            if (res && res.response && res.response.reads) {
                const monthlyUsage = mergeMonthlyReads(res.response.reads);
                handleSetUsage(monthlyUsage);
            }
        })
    }

    const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
        let usage: any = {}
        for (let i in data) {
            const key = moment(data[i].end_date, 'MM/DD/YYYY').format('M');
            usage[key] = usage[key] ? usage[key] + parseInt(data[i].actual_kwh) : parseInt(data[i].actual_kwh);
        }
        return usage;
    }

    useEffect(() => {
        let timer = setInterval(() => {
            if (document.getElementById('action-required')) {
                const wrapper: any = document.getElementById('agreement-wrapper');
                const scrollTo: any = document.getElementById('action-required');
                const topPos = scrollTo.offsetParent.offsetParent.offsetTop;
                wrapper.scrollTop = topPos - 260;

                clearInterval(timer);
                if (typeof window !== 'undefined') {
                    window.scrollTo(0, 0);
                }
            }
        }, 300)
    
        return (() => {
            clearInterval(timer)
        })
    }, [agreement])

    const createMarkup = () => {
        let html = ''
        if (agreement) {
            html = he.decode(agreement)
            html = html.replace(/<i>ACTION REQUIRED:/, '<i id="action-required">ACTION REQUIRED:')
            html = html.replace(/<html><body>/, '').replace(/<\/body><\/html>/, '')
        }
        return {__html: html}
    }

    useEffect(() => {
        const confirmSmtRequest = (link: string) => {
            setShowConfirmModal(true);
        }
    
        const rejectSmtRequest = () => {
            snackbarShowMessage?.('Unfortunately, Please apply again next time', 'error');
            // console.error('Unfortunately, Please apply again next time');
            handleBack()
        }
    
        const openLink = (link: string) => {
            if (typeof window !== 'undefined') {
                const w = window.open('about:blank')
                if (w) w.location.href = link
            }
        }
    
        const agreementListener = (evt: any) => {
            evt.preventDefault();
            let link = ''
            let btnText = ''
            const target = evt.target;
            if (target.tagName.toLowerCase() === 'b' && target.parentNode.tagName.toLowerCase() === 'a') {
                link = target.parentNode.href
                btnText = target.innerText.toLowerCase()
            }
            else if (target.tagName.toLowerCase() === 'a') {
                link = target.href
                btnText = target.innerText.toLowerCase()
            }
            if (link && btnText) {
                switch(btnText) {
                    case 'confirm': confirmSmtRequest(link); break;
                    case 'do not confirm': rejectSmtRequest(); break;
                    case 'did not request': rejectSmtRequest(); break;
                    default: openLink(link);
                }
            }
        }

        if (agreementContent && agreementContent.current) {
            const current = agreementContent.current
            current.addEventListener('click', agreementListener)
            return (() => {
                if (agreementContent && current) {
                    current.removeEventListener('click', agreementListener)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement])

    return (
        <div>
            {
                loading &&
                <div>
                    <LoadingRow />
                    <p style={{fontSize:17,maxWidth:500,margin:'32px auto 0',textAlign:'center',color:'#666'}}>
                        {
                            agreement ?
                            'We are downloading your data and analyzing your energy consumption trends' : 'The agreement to access your smart meter data is being initiated, and this will take less than a minute'
                        }
                    </p>
                </div>
            }
            {
                (!loading && email) &&
                <>
                    <div style={{fontSize:14,color:'#666'}}><b>To: {email}</b><br/>From: SMT</div>
                    <div style={{margin:'12px 0'}}>
                        <img src="/images/agreement-tips-confirm.png" width="121" alt="" />
                        <span style={{fontSize:15,lineHeight:1}}>Please click the <b>Confirm</b> yellow box in below</span>
                    </div>
                </>
            }
            <div
                id="agreement-wrapper"
                dangerouslySetInnerHTML={createMarkup()}
                ref={agreementContent}
                style={{opacity: loading ? 0 : 1}}
            />
            <ConfirmModal
                show={showConfirmModal}
                handleCloseModal={() => {
                    setShowConfirmModal(false);
                }}
                url={confirmUrl}
                handleContinue={() => {
                    handleCheckAgreement('confirm');
                }}
            />
        </div>
    )
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ show, handleCloseModal, url, handleContinue }) => {
    const [ loading, setLoading ] = useState(true);
    
    const closeModal = (evt: any, reason: string) => {
        if (reason === 'backdropClick') return;
        handleCloseModal();   
    }

    return (
        <Modal
            open={show}
            onClose={closeModal}
        >
            <Box sx={ModalBodyStyle}>
                <iframe
                    title="success-page"
                    src={url}
                    width="100%"
                    height="400"
                    className="modal-iframe"
                    onLoad={() => {
                        setLoading(false);
                    }}
                />
                <div style={{textAlign:'center',marginTop:8}}>
                    {
                        loading && <Loading />
                    }
                    {
                        !loading &&
                        <Button variant="contained" type="submit" onClick={() => {
                            if (loading) return;
                            handleCloseModal();
                            handleContinue();
                        }}>Continue</Button>
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default withSnackbar(SmtShowAgreement)