import { Button } from "@mui/material"
import { Box, Container } from "@mui/system"
import { BenefitItem, BenefitRow, BenefitSectionHeading } from "./styles/saving-section.styled"

export const BenefitSection = ({ utms }: {
    utms: string;
}) => {
    return (
        <Container>
            <BenefitSectionHeading>
                <h2>Pack Benefits</h2>
                <p>Every GoodCharlie Pack member receives our free Pack Benefits with every energy plan</p>
            </BenefitSectionHeading>
            <BenefitRow>
                <BenefitItem>
                    <img src="/images/benefit-icon-1.svg" alt="" />
                    <h6>Video Vet</h6>
                    <p>Free 24/7 immediate access to licensed veterinarians.</p>
                </BenefitItem>
                <BenefitItem>
                    <img src="/images/benefit-icon-2.svg" alt="" />
                    <h6>Emergency Fund</h6>
                    <p>$750 to fund an emergency vet bill. No claim process, no deductible, no co-insurance.</p>
                </BenefitItem>
                <BenefitItem>
                    <img src="/images/benefit-icon-3.svg" alt="" />
                    <h6>Roundup For Rescues</h6>
                    <p>We round up every bill and donate it to a rescue partner - at no cost to customers.</p>
                </BenefitItem>
            </BenefitRow>
            <Box mt={3}>
                <Button size="large" variant="contained" onClick={() => {
                    window.location.href = `https://enrollment.goodcharlie.com/${utms ? `?${utms}` : ''}`
                }} sx={{mt:3}}>Join The Pack</Button>
            </Box>
        </Container>
    )
}