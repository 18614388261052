import { styled } from '@mui/system';

export const SectionWrapper = styled('div')`
    text-align: center;

    p {
        font-size: 18px;
        line-height: 32px;
        margin: 0;
    }

    @media (max-width: 991px) {
        p {
            text-align: left;
        }
    }
`

export const ReviewsSectionHeading = styled('div')`
    h2 {
        margin: 0 0 16px;
        font-size: 2.125rem;
        text-align: center;
    }

    p {
        text-align: center;
    }
`

export const ReviewsWrapper = styled('div')`
    padding: 3.5rem 0px 0px 8.68vw;
`

export const ReviewsContainer = styled('div')`
    @keyframes slide{from{-webkit-transform:translateX(0%);-ms-transform:translateX(0%);transform:translateX(0%);}to{-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}}

    display: inline-flex;
    width: calc(184rem);
    animation: 56s linear 0s infinite normal both running slide;

    &:hover {
        animation-play-state: paused;
    }
    
    @media (max-width: 767.98px) {
        width: calc(136rem);
    }
`

export const ReviewBox = styled('div')`
    padding: 2.8125rem 0.5rem 3.5rem;
    min-width: 23rem;
    text-align: left;
    box-sizing: border-box;

    @media (max-width: 767.98px) {
        padding-top: 2.125rem;
        min-width: 17rem;
    }
`

export const ReviewInner = styled('div')`
    background: #fff;
    border: 3px solid #ffe9ee;
    box-shadow: rgb(224 224 224 / 20%) 0px 9px 30px;
    border-radius: 24px;
    padding: 0px 2.625rem 3.625rem 3.375rem;
    display: flex;
    flex-direction: column;
    min-height: 27.5rem;
    height: 27.5rem;
    max-height: 100%;
    transition: transform 0.25s ease 0s;
    box-sizing: border-box;

    &:hover {
        transform: scale(1.015);
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        min-height: 25rem;
        height: 25rem;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0px 1.625rem 3.625rem 2.375rem;
    }

    @media (max-width: 767.98px) {
        padding: 0px 1.875rem 2.625rem 2.5rem;
        min-height: 22rem;
        height: 22rem;
    }
`

export const ReviewThumbnail = styled('div')`
    width: 89px;
    height: 89px;
    border-radius: 50%;
    position: relative;
    margin: -2.8125rem 0px 1.5rem;
    flex-shrink: 0;

    span {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    @media (max-width: 767.98px) {
        width: 66px;
        height: 66px;
        margin: -2.125rem 0px 1.125rem;
    }
`

export const Reviewer = styled('div')`
    h5 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 28px;
        letter-spacing: -0.28px;
        color: #3a3a3a;
        margin-bottom: 0.25rem;
        margin-top: 0;
    }

    p {
        font-size: 0.875rem;
        line-height: 20px;
        color: #0bcebb;
    }

    p a {
        text-decoration: none;
        color: #0bcebb;
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        h5 {
            font-size: 1.125rem;
            line-height: 22px;
        }
    }

    @media (max-width: 767.98px) {
        h5 {
            font-size: 1rem;
            line-height: 21px;
            letter-spacing: -0.2px;
        }
    }

    @media (max-width: 1199.98px) {
        p {
            font-size: 0.75rem;
        }
    }
`

export const ReviewRank = styled('div')`
    margin: 1.25rem 0px 1.375rem;

    @media (max-width: 767.98px) {
        margin: 1rem 0px 1.125rem;
    }
`

export const ReviewComment = styled('div')`
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    letter-spacing: -0.35px;
    color: #3a3a3a;

    @media (min-width: 768px) and (max-width: 1199.98px) {
        font-size: 1.125rem;
        line-height: 26px;
    }

    @media (max-width: 767.98px) {
        font-size: 1rem;
        line-height: 22px;
        letter-spacing: -0.26px;
    }
`

export const BenefitSectionHeading = styled('div')`
    h2 {
        margin: 0 0 16px;
        font-size: 2.125rem;
        text-align: center;
    }

    p {
        text-align: center;
    }
`

export const BenefitRow = styled('div')`
    padding-top: 3.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (min-width: 576px) and (max-width: 991.98px) {
        justify-content: space-around;
    }

    @media (min-width: 0) and (max-width: 575.98px) {
        -webkit-box-pack: center;
        justify-content: center;
    }
`

export const BenefitItem = styled('div')`
    max-width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        border: 1px solid #f3f3f3;
        box-shadow: rgb(224 224 224 / 20%) 0px 9px 30px;
        border-radius: 50%;
    }

    h6 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.28px;
        color: #3a3a3a;
        margin: 1.875rem 0px 1rem;
    }

    p {
        font-weight: 600;
        text-align: center;
        line-height: 24px;
        color: #212121b3;
        font-size: 1rem;
    }

    @media (max-width: 991.98px) {
        margin-bottom: 2rem;
    }
`

export const CopmareSection = styled('div')`
    h2 {
        font-size: 1.875rem;
        font-weight: 300;
        max-width: 855px;
        margin: 0px auto 32px;
        line-height: 1.5;
    }

    h3 {
        font-size: 2.5rem;
        text-align: left;
        margin-bottom: 26px;
    }

    @media (max-width: 768px) {
        h3 {
            font-size: 2.125rem;
        }
    }
`