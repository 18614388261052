import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Homepage from './homepage';
import Compare from './compare';
import { PageWrapper, PageMain } from './components/styles/common.styled';
import './App.css';
import { useEffect, useState } from 'react';
import { fetchPhone } from './fetch/fetch';
import { getURLParam, getUtmParameter } from './common';

declare module '@mui/material/Button/Button' {
	interface ButtonPropsVariantOverrides {
		light: true;
	}
}

const globalTheme = createTheme({
	palette: {
		primary: {
			main: '#FF5A6A',
			dark: '#E54958',
			light: '#FFE9EE'
		},
		secondary: {
			main: '#0BCEBB',
			light: '#CEF5F1'
		}
	}
})

const theme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: 14
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: 14,
					'& label': {
						fontFamily: "Nunito",
					},
					'& fieldset': {
						borderRadius: 14,
						borderColor: '#e6e6e6'
					},
					'& input': {
						fontFamily: "Nunito",
						'&:-webkit-autofill': {
							transitionDelay: '9999s',
							transitionProperty: 'background-color, color'
						}
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					backgroundColor: '#fff'
				},
				select: {
					paddingTop: 14,
					paddingBottom: 14
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontFamily: "Nunito"
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "Nunito",
					borderRadius: 50,
					fontWeight: 700,
					boxShadow: '0 18px 30px #FF5A6A4D',
					textTransform: 'inherit'
				},
				sizeLarge: {
					fontSize: 16,
					padding: '16px 40px'
				},
				sizeMedium: {
					padding: '12px 20px'
				},
				sizeSmall: {
					boxShadow: '0 12px 24px #FF5A6A4D'
				}
			},
			variants: [{
				props: { variant: 'contained' },
				style: {
					'&:hover': {
						backgroundColor: '#E64958',
						boxShadow: '0 18px 30px #FF5A6A66',
					}
				}
			}, {
				props: { variant: 'outlined' },
				style: {
					boxShadow: 'none',
					'&:hover': {
						backgroundColor: 'transparent',
						color: globalTheme.palette.primary.dark
					}
				}
			}, {
				props: { variant: 'light' },
				style: {
					backgroundColor: '#ffe9ee80',
					color: globalTheme.palette.primary.main,
					'&:hover': {
						backgroundColor: '#ffe9ee80',
						color: globalTheme.palette.primary.dark
					}
				}
			}, {
				props: { variant: 'text' },
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					padding: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}
			}]
		}
	}
}, globalTheme);

function App() {
	const [ isSticky, setIsSticky ] = useState(false);
	const [ gcPhone, setGcPhone ] = useState('');
	const [ utms, setUtms ] = useState('');

	useEffect(() => {
		const scrollEvent = () => {
			const scrollY = window.scrollY;
			if (scrollY <= 1) {
				setIsSticky(false);
			}
			else {
				setIsSticky(true);
			}
		}

		window.addEventListener('scroll', scrollEvent);

		return () => {
			window.removeEventListener('scroll', scrollEvent);
		}
	}, [])

    useEffect(() => {
		const { search } = window.location || {};
		if (search) {
			const utmContent = getURLParam('utm_content');
			if (utmContent) {
				fetchPhone(utmContent).then(res => {
				    if (res && res.response && res.response.phone_number) {
				        setGcPhone(res.response.phone_number);
				    }
				})
			}
		}
		const utmsParameter = getUtmParameter();
		setUtms(utmsParameter);
    }, [])

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<PageMain>
					<Header gcPhone={gcPhone} utms={utms} isSticky={isSticky} />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage utms={utms} />} />
							<Route path="/compare" element={<Compare utms={utms} />} />
						</Routes>
					</BrowserRouter>
				</PageMain>
				<Footer gcPhone={gcPhone} />
			</PageWrapper>
		</ThemeProvider>
	);
}

export default App;
