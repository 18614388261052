import { Box } from "@mui/material";
import { ReviewsSectionHeading, ReviewsWrapper, ReviewsContainer, ReviewBox, ReviewInner, ReviewThumbnail, Reviewer, ReviewRank, ReviewComment } from "./styles/saving-section.styled";

export const ReviewSection = () => {
    return (
        <>
            <ReviewsSectionHeading>
                <h2>What the Pack is Barking About</h2>
                <p>See what others are saying about GoodCharlie.</p>
            </ReviewsSectionHeading>
            <ReviewsWrapper>
                <Box overflow="hidden">
                    <ReviewsContainer>
                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/julia_morales_clark.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Julia Morales Clark</h5>
                                    <p>Houston Astros Sports Reporter,</p>
                                    <p>AT&T Sports Net</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>I'm thrilled that GoodCharlie automatically supports my favorite rescue, Houston Pets Alive!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/marilyn_litt.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Marilyn Litt</h5>
                                    <p>Executive Director,</p>
                                    <p><a href="https://lostdogsoftexas.org/" target="_blank" rel="noreferrer">Lost Dogs of Texas</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>How amazing that GoodCharlie energy company wants to help lost dogs go home as much as I do!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/rounded_cat_image.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Queenie</h5>
                                    <p>Cat Pack member</p>
                                    <p>&nbsp;</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>It's not all about dogs! GoodCharlie helps me and my feline friends too!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/michael_kaminsky.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Michael Kaminsky</h5>
                                    <p>Owner,</p>
                                    <p><a href="https://www.fidoruns.com/" target="_blank" rel="noreferrer">Run Fido Run</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>The way GoodCharlie supports rescues and pet owners is smart, innovative, and awesome!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/lisa_foronda.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Lisa Foronda Harper</h5>
                                    <p>Former Anchor,</p>
                                    <p>KHOU Channel 11 News</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>Signing up with GoodCharlie is one more way I can contribute to the welfare of our four-legged friends and their families.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/leslie_sans.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Leslie Sans</h5>
                                    <p>Executive Director,</p>
                                    <p><a href="https://dallaspetsalive.org/" target="_blank" rel="noreferrer">Dallas Pets Alive!</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>GoodCharlie's commitment to animals in need allows us to save the most vulnerable pets in North Texas.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/kilyn_horton.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Kilyn Horton</h5>
                                    <p>Animal Advocate,</p>
                                    <p><a href="https://www.instagram.com/khbubbles" target="_blank" rel="noreferrer">@khbubbles</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>I love that GoodCharlie supports the animals and rescue organizations of Texas.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/adam_stetson.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Adam Stetson</h5>
                                    <p>Chairman of the Board,</p>
                                    <p><a href="https://dallaspetsalive.org/" target="_blank" rel="noreferrer">Dallas Pets Alive!</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>GoodCharlie's focus on going the extra mile for dog welfare provides DPA the ability to increase programs and resources.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        {/* --- */}

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/julia_morales_clark.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Julia Morales Clark</h5>
                                    <p>Houston Astros Sports Reporter,</p>
                                    <p>AT&T Sports Net</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>I'm thrilled that GoodCharlie automatically supports my favorite rescue, Houston Pets Alive!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/marilyn_litt.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Marilyn Litt</h5>
                                    <p>Executive Director,</p>
                                    <p><a href="https://lostdogsoftexas.org/" target="_blank" rel="noreferrer">Lost Dogs of Texas</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>How amazing that GoodCharlie energy company wants to help lost dogs go home as much as I do!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/rounded_cat_image.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Queenie</h5>
                                    <p>Cat Pack member</p>
                                    <p>&nbsp;</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>It's not all about dogs! GoodCharlie helps me and my feline friends too!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/michael_kaminsky.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Michael Kaminsky</h5>
                                    <p>Owner,</p>
                                    <p><a href="https://www.fidoruns.com/" target="_blank" rel="noreferrer">Run Fido Run</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>The way GoodCharlie supports rescues and pet owners is smart, innovative, and awesome!</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/lisa_foronda.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Lisa Foronda Harper</h5>
                                    <p>Former Anchor,</p>
                                    <p>KHOU Channel 11 News</p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>Signing up with GoodCharlie is one more way I can contribute to the welfare of our four-legged friends and their families.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/leslie_sans.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Leslie Sans</h5>
                                    <p>Executive Director,</p>
                                    <p><a href="https://dallaspetsalive.org/" target="_blank" rel="noreferrer">Dallas Pets Alive!</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>GoodCharlie's commitment to animals in need allows us to save the most vulnerable pets in North Texas.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/kilyn_horton.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Kilyn Horton</h5>
                                    <p>Animal Advocate,</p>
                                    <p><a href="https://www.instagram.com/khbubbles" target="_blank" rel="noreferrer">@khbubbles</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>I love that GoodCharlie supports the animals and rescue organizations of Texas.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>

                        <ReviewBox>
                            <ReviewInner>
                                <ReviewThumbnail><span><img src="/images/reviewers/adam_stetson.webp" alt="" /></span></ReviewThumbnail>
                                <Reviewer>
                                    <h5>Adam Stetson</h5>
                                    <p>Chairman of the Board,</p>
                                    <p><a href="https://dallaspetsalive.org/" target="_blank" rel="noreferrer">Dallas Pets Alive!</a></p>
                                </Reviewer>
                                <ReviewRank>
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                    <img src="/images/Star.svg" alt="" />
                                </ReviewRank>
                                <ReviewComment>GoodCharlie's focus on going the extra mile for dog welfare provides DPA the ability to increase programs and resources.</ReviewComment>
                            </ReviewInner>
                        </ReviewBox>
                    </ReviewsContainer>
                </Box>
            </ReviewsWrapper>
        </>
    )
}