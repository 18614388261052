// import { useEffect, useState } from "react";
import {
    AddressDetailRow, AddressWrapper, /* MapMarker, */ MapWarpper
} from "./styles/compare.styled"
// import GoogleMapReact from 'google-map-react';
import { PremiseDetailProps } from "../types";
import NumberFormat from 'react-number-format';
// import { GOOGLE_API_KEY } from "../config";
// import { fetchCoordinates } from "../fetch/fetch";
import { UsageAdjust } from "./UsageAdjust";
import { Button } from "@mui/material";
import { GetUsageEntry } from "./styles/usage.styled";
import { Loading } from "./Loading";

export const CompareAside = ({
    premiseDetail, premiseLoading, address,
    usages, handleShowSidebar, handleGetEstUsage,
    handleChangeUsage, sourceFrom, isEstMode
}: {
    premiseDetail: PremiseDetailProps|undefined,
    premiseLoading: boolean,
    address: string,
    usages: number[],
    handleShowSidebar: Function;
    handleGetEstUsage: Function;
    handleChangeUsage: Function;
    sourceFrom: string;
    isEstMode: boolean;
}) => {
    // const [ location, setLocation ] = useState({ lng: 0, lat: 0 });

    // useEffect(() => {
    //     if (!address) return;
        
    //     fetchCoordinates(address).then(res => {
    //         if (res && res.status === 'OK') {
    //             const { results } = res;
    //             if (results && results.length > 0) {
    //                 const { location } = results[0].geometry;
    //                 // console.log(location)
    //                 setLocation(location);
    //             }
    //         }
    //     })
    // }, [address])

    const { lot_size, bathrooms, bedrooms } = premiseDetail || {};
    
    return (
        <div>
            <AddressWrapper>
                <i />
                <div>
                    {
                        premiseLoading ? <Loading /> :
                        <>
                            <b>{address}</b>
                            <AddressDetailRow>
                                { lot_size && <span><NumberFormat value={lot_size} displayType={'text'} thousandSeparator={true} /> SqFt</span> }
                                { bedrooms && <span>{bedrooms} Bedrooms</span> }
                                { bathrooms && <span>{bathrooms} Bathrooms</span> }
                            </AddressDetailRow>
                        </>
                    }
                </div>
            </AddressWrapper>
            <MapWarpper>
                {
                    address &&
                    <iframe
                        width="100%"
                        height="100%"
                        style={{border:0}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0&q=${encodeURIComponent(address)}&zoom=10`}>
                    </iframe>
                }
                {
                    // (location.lat && location.lng) ?
                    // <GoogleMapReact
                    //     bootstrapURLKeys={{key: GOOGLE_API_KEY}}
                    //     defaultZoom={10}
                    //     center={location}
                    // >
                    //     <Marker
                    //         lat={location.lat}
                    //         lng={location.lng}
                    //         text="Marker"
                    //     />
                    // </GoogleMapReact> :
                    // null
                }
            </MapWarpper>
            <UsageAdjust
                usage={usages}
                disabled={false}
                handleChangeUsage={(usages: string[]) => handleChangeUsage(usages)}
                handleReset={() => handleGetEstUsage()}
                isEstMode={isEstMode}
                handleSwitchToEstMode={() => handleGetEstUsage()}
                sourceFrom={sourceFrom}
            />
            {
                isEstMode &&
                <GetUsageEntry>
                    <p>Retrieve real-time usage within minutes by allowing access.</p>
                    <Button
                        variant="contained"
                        onClick={() => handleShowSidebar()}
                    >
                        Get My Usage
                    </Button>
                </GetUsageEntry>
            }
        </div>
    )
}

// const Marker = ({ text }: {
//     text: string,
//     lat: number,
//     lng: number
// }) => {
//     return (
//         <MapMarker>{text}</MapMarker>
//     )
// }