import React from 'react';
import type { LoadingRowProps } from '../types';
import { Loading } from './Loading';

export const LoadingRow: React.FC<LoadingRowProps> = ({ size }) => {
    if (size === 'sm') {
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <Loading sm />
            </div>
        )
    }
    return (
        <div style={{display:'flex',justifyContent:'center'}}>
            <Loading />
        </div>
    )
}