import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';
import { TooltipIcon } from './styles/common.styled';
import { isMobile } from 'react-device-detect';

export const MyTooltip = styled(({ className, ...props }: any) => {
    const [ open, setOpen ] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    }

    const handleTooltipOpen = () => {
        setOpen(true);
    }

    if (isMobile) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    {...props}
                    arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    classes={{ popper: className }}
                    placement="top"
                    enterTouchDelay={0}
                    onClose={handleTooltipClose}
                    open={open}
                    title={props.title}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <TooltipIcon onClick={handleTooltipOpen} />
                </Tooltip>
            </ClickAwayListener>
        )
    }
    return (
        <Tooltip
            {...props}
            arrow
            classes={{ popper: className }}
            placement="top"
            enterTouchDelay={0}
            title={props.title}
        >
            <TooltipIcon />
        </Tooltip>
    )
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#EDFFFC',
        color: theme.palette.secondary.main,
        padding: '18px 16px',
        maxWidth: 300,
        fontSize: 13,
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 8,
        lineHeight: '18px',
        fontFamily: "Nunito",
        fontWeight: 700
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#EDFFFC'
        },
    }
}));